import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';

import 'swiper/css';
import "swiper/css/navigation";
import {useRouter} from "../../components/Shared/Router/Router";
import { AuthContext } from '../../context/Auth/AuthProvider';

const PasswordReset = () => {
  
    const [updated, setUpdated] = useState(false);
    const router = useRouter();
    const { redirectPasswordReset } = useContext(AuthContext)
    const [passwordVerifyCode, setPasswordVerifyCode] = useState(null)

    useEffect(() => {
        const routerUrlPath = router.pathname.split('/');
        const pwCode = routerUrlPath[routerUrlPath.length - 1];    
        setPasswordVerifyCode(pwCode)
        redirectPasswordReset(pwCode)
    }, [passwordVerifyCode])

    const UI = () => {

        return (
            <Fragment>

                <head>
                    <title>Passwort zurücksetzen</title>
                </head>
                <div className="mt-4">
                    <section id="uvi_subscription_section">
                        <div className="row">
                            <div className="col-12">
                                <div className="card p-md-5" id="uvi_subscription">
                                    <div className="card-body p-5">
                                        <h1 className="uvi-title text-center mb-3">Sie werden automatisch weitergeleitet...</h1>
                                        <div className="row">
                                            <div className="col-12 col-md-6" id="subsciptionBtn">
                                               <p id="uvis_text"> Falls dies nicht funktioniert:</p>
                                                <button type="button" onClick={() => redirectPasswordReset(passwordVerifyCode)}  className={'btn btn-primary'}  > Passwort zurücksetzen </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                </div>


            </Fragment>
        )
    }
    
    return(<Fragment>
        {UI()}
    </Fragment>)
}

PasswordReset.requiredScope = ['']
PasswordReset.requireAuth = false;  // true -> force login via AuthContext, false -> no login required so no user available either
export default PasswordReset;
