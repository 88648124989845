import React, {Fragment, useState, useEffect, createContext, useContext, useRef } from 'react';
import {useCallback} from "react";
import querystring from "query-string";

import Cookie from 'js-cookie';
import CryptoJS from "crypto-js";
import {Base64} from 'js-base64';
import sha256 from 'js-sha256';
import utf8 from 'utf8';
import {v1 as uuidv1} from 'uuid';
import { useRouter } from "../../components/Shared/Router/Router";
import axios from 'axios'
import { CookieContext } from "../CookieMaster/CookieMaster";
import AxiosClient from "../../utils/axios_client";
import cookies from "js-cookie";
import Swal from "@molline/sweetalert2";

const oauth = (process.env.NODE_ENV === 'production')? 'https://auth.molline.digital' : 'http://localhost:7002'
//const COOKIE_PARAMS = { "expires":3, "httpOnly": false  };
const COOKIE_PARAMS = {
    expires: 3, 
    httpOnly: false, 
    secure: (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'production' )? true:false, 
    domain: (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'production' )? 'molline-connect.de':'localhost',
    path: "/",
};

export const AuthContext = createContext();

export function setRedirect(redirect){
    Cookie.set("redirect", redirect, COOKIE_PARAMS)
}

export function getRedirect() {
    return Cookie.get("redirect");
}

export function clearRedirect(){
    return Cookie.remove("redirect", COOKIE_PARAMS);
}

const AuthProvider = ({ children }) => {

    const [updated, setUpdated] = useState(false);
    const [initializing, setInitializing] = useState(true)
    const [user, setUser] = useState(undefined);
    const [session, setSessionId] = useState(null);
    const {setCookie, getCookie, cookieDestroy, generate} = useContext(CookieContext)
    const userChanged = useCompare(user)
    const router = useRouter();

    useEffect(   () => {
    
        if(!updated){
            setInitializing(false)
            setUpdated(true);
        }

    }, [user, userChanged, session])

    function useCompare (val) {
        const prevVal = usePrevious(val)
        return prevVal !== val
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const dencUser = () => {
        try{
            let encUser = localStorage.getItem('encuser')
            let bytes  = CryptoJS.AES.decrypt(encUser, getCookie('APP_KEY'));
            let userPayload = bytes.toString(CryptoJS.enc.Utf8);
            userPayload = JSON.parse(userPayload)
            return userPayload
        }catch(err){
            console.log('USER DECRYPTION ERROR')
        }
    }

    const redirectUser = () => {
        /**
         * Initialization should be over now. On first redirect the user must be set up.
         */
        let code_verifier = getCookie('code_verifier');
        let encUser = localStorage.getItem('encuser')
        let auth = getCookie('auth')
        /**
         * If user is just now logging in, then complete this process
         */
        if(!user && !!code_verifier && !encUser && !auth){
            axios.get(`${oauth}/api/artifact?code=${code_verifier.slice(0,32)}`)
                .then(results => {
                    if(results.data.length !== 0){
                        let key = sha256(utf8.encode(code_verifier))
                        let bytes  = CryptoJS.AES.decrypt(results.data, key);
                        let userPayload = bytes.toString(CryptoJS.enc.Utf8);
                        userPayload = JSON.parse(userPayload)
                        Cookie.set('auth', userPayload.access_token, COOKIE_PARAMS)

                        key = Cookie.get('APP_KEY')
                        let encUser = CryptoJS.AES.encrypt( JSON.stringify(userPayload), key ).toString();
                        localStorage.setItem('encuser', encUser);
                        localStorage.setItem('organization', JSON.stringify(userPayload.organization));

                        delete userPayload.access_token;

                        setUser(userPayload)
                        cookieDestroy('code_verifier')
                    } else  {
                        login();
                    }
                })
                .catch(err => {
                    console.log('AXIOS ARTIFACT ERROR',err)
                    login();
                })
        }else if(!user && !!auth & !!encUser){
            let decryptedUser = dencUser()
            if(typeof decryptedUser === 'object' && decryptedUser !== null)  {
                setUser(decryptedUser)
            } else {
                Swal.fire({
                    title: `Session ist abgelaufen!`,
                    text: `Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein :)`,
                    icon: 'warning',
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    allowEnterKey: false,
                    showConfirmButton: true,
                    showCancelButton: false,
                    background: localStorage.getItem('theme-preference') === 'dark-layout' ? '#161D31' : '#fff',
                    confirmButtonText: 'Ok',
                })
                    .then(() => {
                        cookieDestroy('auth');
                        cookieDestroy('encuser');
                        cookieDestroy('organization');
                        cookieDestroy('session');
                        localStorage.removeItem('encuser')
                        localStorage.removeItem('organization')
                        redirectUser()
                    })
            }

        } else {

            if(window.location.pathname == "/erklaervideos") {
                setCookie('redirect', window.location.pathname);
            }
            login();
        }
    }

    const  login =   () => {
        let code_verifier = getCookie('code_verifier');
        if(!code_verifier){
            code_verifier = generate(64)
            setCookie('code_verifier', code_verifier)
        }
        const code_challenge = Base64.encode(sha256(utf8.encode(code_verifier)));
        const LOGINURL = `${process.env.REACT_APP_AUTH_API}${process.env.REACT_APP_AUTH_URI}?code_challenge=${code_challenge}&client_id=${process.env.REACT_APP_AUTH_CLIENTID}&redirect_url=${process.env.REACT_APP_AUTH_CALLBACK}`;
    //    const LOGINURL = `http://localhost:8100/login?code_challenge=${code_challenge}&client_id=${process.env.REACT_APP_AUTH_CLIENTID}&redirect_url=${process.env.REACT_APP_AUTH_CALLBACK}`;

        window.location.href = LOGINURL;
    }

    const  redirectPasswordReset =   (password_verify_code) => {
        let code_verifier = getCookie('code_verifier');
        if(!code_verifier){
            code_verifier = generate(64)
            setCookie('code_verifier', code_verifier)
        }
        const code_challenge = Base64.encode(sha256(utf8.encode(code_verifier)));
        const LOGINURL = `${process.env.REACT_APP_AUTH_API}/password/code?code_challenge=${code_challenge}&client_id=${process.env.REACT_APP_AUTH_CLIENTID}&redirect_url=${process.env.REACT_APP_AUTH_CALLBACK}&verify=${password_verify_code}`;
        window.location.href = LOGINURL;
    }

    const logout = () => {
        cookieDestroy('auth');
        cookieDestroy('encuser');
        cookieDestroy('organization');
        cookieDestroy('session');
        localStorage.removeItem('encuser')
        localStorage.removeItem('organization')
        localStorage.removeItem('theme-preference')
        cookieDestroy('redirect')
        window.location.href = '/home'
        router.push('/home')
    }

    const getUser = async (user_id) => {
        const response =   await AxiosClient.backendClient({
            sessionCookie: cookies.get('session'),
            userCookie: cookies.get('auth'),
            app_key: cookies.get('APP_KEY')

        }).get(`${process.env.REACT_APP_API_OPENMETER}/user?user_id=${user_id}`)
        return response.data
    }

    const auth = {
        setUser,
        user,
        session,
        initializing,
        setRedirect,
        getRedirect,
        clearRedirect,
        logout,
        login,
        getUser,
        redirectUser,
        dencUser,
        redirectPasswordReset
    }

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;
