import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';

import uniqid from 'uniqid';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";

import Swal from "@molline/sweetalert2";
import 'swiper/css';
import "swiper/css/navigation";
import AxiosClient from "../../utils/axios_client";
import LocationDetails from "../Dashboard/details/location/locationDetail";
import {useRouter} from "../../components/Shared/Router/Router";


const UviOptOut = () => {
  
    const [updated, setUpdated] = useState(false);
    const [isOptOut, setIsOptOut] = useState(false);
    const router = useRouter();
    
    useEffect(() => {
        
    }, [isOptOut])


    const handleSubscription = () => {
        if(!!isOptOut) {
            Swal.fire({
                title: 'Erfolg',
                icon: 'info',
                text: "Sie haben sich erfolgreich abgemeldet.",
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: false,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: 'Ok'
            })
        } else {
            const routerUrlPath = router.pathname.split('/');
            const tenantToken = routerUrlPath[routerUrlPath.length - 1];
            AxiosClient.backendClient().get(`${process.env.REACT_APP_API_OPENMETER}/tenant/unsubscribe?token=${tenantToken}&mail=0`, {})
                .then(response => {
                    setIsOptOut(true);
                    Swal.fire({
                        title: 'Erfolg',
                        icon: 'success',
                        text: "Sie haben sich erfolgreich abgemeldet.",
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'Ok'
                    })
                })
                .catch(err => {
                    Swal.fire({
                        title: 'Error',
                        icon: 'error',
                        text: `Leider ist bei der Abmeldung ein Fehler aufgetreten. Bitte wenden Sie sich an Ihren Verwalter`,
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'Ok'
                    })
                })
        }




    }

    const UI = () => {

        return (
            <Fragment>

                <head>
                    <title>MOLLINÉ CONNECT Monatlichen Verbrauchsinformationen</title>
                </head>
                <div className="mt-4">
                    <section id="uvi_subscription_section">
                        <div className="row">
                            <div className="col-12">
                                <div className="card p-md-5" id="uvi_subscription">
                                    <div className="card-body p-5">
                                        <h1 className="uvi-title text-center mb-3">MOLLINÉ CONNECT Monatlichen Verbrauchsinformationen</h1>
                                        <div className="row">
                                            <div className="col-12 col-md-6" id="subsciptionBtn">
                                               <p id="uvis_text"> Wollen Sie wirklich ab sofort keine Emails mit ihren monatlichen Verbrauchsinformationen mehr erhalten?</p>
                                                <button type="button" onClick={() => handleSubscription()}  className={`btn ${isOptOut ? 'btn-success': 'btn-dark' }`}  > abmelden </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                </div>


            </Fragment>
        )
    }
    
    return(<Fragment>
        {UI()}
    </Fragment>)
}

UviOptOut.requiredScope = ['']
UviOptOut.requireAuth = false;  // true -> force login via AuthContext, false -> no login required so no user available either
export default UviOptOut;
